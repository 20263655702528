export const config = () => ({
    functions: {
        domainUrl: 'https://www.clearandfizzy.com',
        dataUrl: '/.netlify/functions/data',
    },
    header: {
        title: 'Gareth Price',
        tagline: 'London based / NodeJS / Typescript / PHP / Open AI developer',
    },
    exampleCode: {
        title: 'Example Code',
        codeLinks: [
            {
                label: "Functional Factory/Builder Pattern (Node/Typescript)",
                url: "https://bitbucket.org/clearandfizzy/functional-factory-builder-pattern/src/main/"
            },
            {
                label: "Todo List (ReactJS/Typescript)",
                url: "https://bitbucket.org/clearandfizzy/test-frontend-developer/src"
            },

        ]
    },
    personProjects: {
        title: 'Personal Projects',
        projectsLinks: [
            {
                label: "Open AI - Chatbot",
                url: "https://echobud.netlify.app/"
            },
            {
                label: "Blog",
                url: "/blog"
            },
            {
                label: "RxJS",
                url: "https://clearandfizzy-rxjs.netlify.app/"
            },
            {
                label: "Lodash",
                url: "https://clearandfizzy-lodash.netlify.app/"
            },
            {
                label: "HTML5",
                url: "https://clearandfizzy-html5.netlify.app/"
            },
            {
                label: "MobX",
                url: "https://clearandfizzy-mobx.netlify.app/"
            },
            {
                label: "ReactJS + MaterialUI",
                url: "https://clearandfizzy-todo.netlify.app/"
            },
            {
                label: "API",
                url: "https://spotify-youtube-import.netlify.app/"
            },
            {
                label: "NextJS",
                url: "https://clearandfizzy-nextjs-example.netlify.app/"
            },
            {
                label: "Bitcoin Tracker – Chrome Extension",
                url: "https://chrome.google.com/webstore/detail/bitcoin-tracker/jbhbjeaollnekdnlkhoajhjfemfmnafp"
            },
            {
                label: "Factory Design Pattern",
                url: "https://bitbucket.org/clearandfizzy/functional-factory-builder-pattern/src/main/"
            }
        ]
    },
    "footer": {
        "title": "Get In Touch",
        "links": [
            {
                label: "Portfolio",
                url: "https://www.clearandfizzy.com",
                link_label: 'www.clearandfizzy.com'
            },
            {
                label: "LinkedIn",
                url: "https://www.linkedin.com/in/garethpricelondon",
                link_label: 'linkedin.com/garethpricelondon'
            },
            {
                label: "Twitter",
                url: "https://twitter.com/garethprice",
                link_label: "twitter.com/garethprice"
            },
            {
                label: "YouTube",
                url: "https://www.youtube.com/@ClearandfizzyLimited",
                link_label: "youtube.com/@ClearandfizzyLimited"
            }
        ],
    }
})